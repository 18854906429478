@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import '~admin-lte/dist/css/adminlte.min.css';

@import '~react-toastify/dist/ReactToastify.min.css';

#root {
  width: 100vw;
  height: 100vh;
}

.main-header .navbar-nav .nav-item .nav-link {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
body{
  overflow-x: hidden !important;
}


.hideNumber_controls::-webkit-outer-spin-button,
.hideNumber_controls::-webkit-inner-spin-button  {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
}
.ExportDropdown{
  top: 3px !important;
  left: -22px !important;
}
thead>tr>td.sorting_asc_disabled:after, table.dataTable thead>tr>td.sorting_desc_disabled:before, table.dataTable thead>tr>td.sorting_desc_disabled:after{
  top: 56% !important;
}
table.dataTable thead>tr>th.sorting:after{
  opacity: 1.125 !important ;
}
table.dataTable thead>tr>th.sorting_asc:before{
  opacity: 0.6 !important 
}

.z-index_3 {
  z-index: 3 !important;
}

iframe {
  pointer-events: none;
}