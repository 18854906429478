th,
td {
  padding: 8px 14px;
  text-align: center !important;
}
tr {
  border-bottom: 1px solid lightgray !important;
}
input[type="number"] {
  /* text-align: center !important; */
  margin: auto  !important;
}
.freeze-table tr:nth-child(1) {
  position: sticky;
  top: 0; 
  z-index: 1;
}
.freeze-table tr:nth-child(2) {
  position: sticky;
  top: 40px !important;
  z-index: 1;
  background: white;
}
.freeze-table tr:nth-child(3) {
  top: 85px !important;
  position: sticky;
  z-index: 1;
  background: white;
}
.freeze-table tr:nth-child(4) {
  top: 130px !important;
  position: sticky;
  z-index: 1;
  background: white;
}
.bg-freeze-color{
  background: white;
}
.freeze-table tr:nth-child(5) {
  top: 175px !important;
  position: sticky;
  z-index: 1;
  background: white;
}
.freeze-table tr:nth-child(6) {
  top: 220px !important;
  position: sticky;
  z-index: 1;
  background: white;
}

.data-row {
  background-color: yellow;
}

.Table {
  width: 76vw;
  white-space: nowrap;
  margin-top: 10px;
  padding-bottom: 200px !important;
  overflow-x: auto;
  display: block !important;
  border-collapse: collapse;
  border: 1px solid lightgray !important;
}
.Table-Editable_cell {
  border-right: 1px solid lightgray !important;
}
.Table-flavors_wapper {
  width: 90% !important;
  overflow-x: auto;
  display: block !important;
}
/* table, td, th {
    border: 1px solid lightgray !important;
} */

.Table tr:first-child th {
  border: 0px solid lightgray !important;
}
/* .Table {
    wid: 100%;
} */
.Table_flavor_view {
  background: red !important;
}

.Table_text {
  font-size: 1.2rem;
  font-weight: 500;
}

.Table-Editable_input {
  text-align: center;
  border: 0px;
  width: 80px !important;
}

.Table-Editable_Rate_input {
  text-align: center;
  border: 0px;
  width: 70px !important;
}

.my-header.focused {
  background-color: #007bff;
  color: #fff;
}

/* ********Btn classes********** */
.Table_addNewRowBtn {
  margin: 7px 10px;
  padding: 9px 16px;
  border: none;
  border-radius: 6px;
  transition: 0.5 ease-in-out;
  background: #007bff;
  color: #ffff;
}
.Table_addNewRowBtn:hover {
  opacity: 0.6;
}
.Table_addNewRowBtn:active {
  translate: 0 2px;
}

/* *****scroll bar******** */
.Table::-webkit-scrollbar {
  width: 12px;
  height: 12px !important;
}

/* Track */
.Table::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px !important;
}

/* Handle */
.Table::-webkit-scrollbar-thumb {
  background: rgb(18, 92, 228);
  border: none !important;
  border-radius: 20px;
}

/* Handle on hover */
.Table::-webkit-scrollbar-thumb:hover {
  background: rgba(18, 92, 228, 0.6);
}

.freeze-table {
  table-layout: fixed !important;
  width: 100% !important;
  border-collapse: collapse !important;
}

/* .freeze-table th:first-child,
.freeze-table td:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 2 !important;
  background-color: white;
} */

.freeze-table .freeze-col {
  position: sticky !important;
  left: 0;
  z-index: 2 !important;
  background-color: white;
}
.freeze-table .freeze-col-input {
  position: sticky !important;
  z-index: 0 !important;
  background-color: white;
}

.left-20 {
  left: 20% !important;
}

.stick-btn {
  position: sticky !important;
  left: 2px !important;
  right: 0;
  top: 5px;
  z-index: 1;
}

/* ************ custom golbal classes **************** */

.d_flex {
  display: flex !important;
}
.hidden_elem {
  opacity: 1 !important;
  visibility: hidden !important;
}

.searchInput {
  min-width: 230px !important;
  width: max-content !important;
}
.searchInput::-webkit-input-placeholder {
  text-align: left !important;
}

.Table_Btn_disable {
  opacity: 0.4 !important;
  pointer-events: none;
}
/* ************* Edit Invoice ************** */
.Table .col-one-freeze {
  position: sticky;
  left: 0;
  background-color: white
}
.Table .col-second-freeze {
  position: sticky;
  left: 16rem;
  background-color: white
}